<template>
    <section>
        <div class="lavish-footer-container px-10 py-6 mobileL:py-16 xl:py-40 p-10 text-white-1 font-normal">
            <div>
                <div class="flex flex-col mobileL:flex-row mobileL:justify-between mobileL:mx-2 xl:mx-12 2xl:mx-28">
                    <div class="font-futura flex flex-col">
                        <h1 class="text-xl mobileL:text-2xl xl:text-3xl 2xl:text-5xl mb-4 mobileL:mb-6 text-center mobileL:text-left">
                            Let's keep in touch?
                        </h1>
                        <div class="flex items-center gap-6 lg:gap-8 mb-4 mobileL:mb-6">
                            <img class="w-9 lg:w-12" src="../../assets/images/home-page/lavish-fb.png" alt="">
                            <a href="https://www.facebook.com/lavishlashesstudio/" target="_blank">
                                <p class="mobileL:underline text-xl mobileL:text-2xl xl:text-4xl">facebook.com/lavishlashesstudio</p>
                            </a>
                        </div>
                        <div class="flex items-center gap-6 lg:gap-8 mb-4 mobileL:mb-6">
                            <img class="w-9 lg:w-12" src="../../assets/images/home-page/lavish-ig.png" alt="">
                            <a href="https://www.instagram.com/lavishlashesstudio/" target="_blank">
                                <p class="mobileL:underline text-xl mobileL:text-2xl xl:text-4xl">@lavishlashesstudio</p>
                            </a>
                        </div>
                    </div>
                    <div class="pt-3 lg:pt-10">
                        <img class="w-72 lg:w-100 2xl:w-110 mb-6 items-center justify-center mx-auto" src="../../assets/images/home-page/lavish-nav-logo.png" alt="lavish lashes logo">
                        <p class="font-semibold text-xl xl:text-2xl text-center hidden xl:block">Copyright © 2023 Lay Bare Waxing Philippines Inc.</p>
                        <p class="text-md text-center block xl:hidden">Copyright © 2023 <br> Lay Bare Waxing Philippines Inc.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
    methods: {
        scrollToId(id)
        {
            document.getElementById(id).scrollIntoView();
        }
    }
}
</script>

<style scoped>
.lavish-footer-container {
    background: url('../../assets/images/home-page/footer-bg.png') no-repeat;
    background-size: cover;
}
</style>