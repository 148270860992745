<template>
    <div class="w-screen overflow-y-hidden header-container bg-magenta-6 fixed font-poppins">
        <div class="w-full px-8 mobileL:px-12 h-full">
            <div class="flex justify-between items-center h-full">
                <img src="../../assets/images/home-page/lavish-nav-logo.png" alt="Lavish Lashes Brand" class="w-40 mobileL:w-48 lg:w-52 xl:w-64 2xl:w-80">   
                <div class="hidden lg:flex lg:items-center gap-8 text-white-1 font-semibold">
                    <router-link class="hover:text-violet-1" to="/lavish-lashes">Home</router-link>
                    <router-link class="hover:text-violet-1" to="/lavish-lashes/branches">Location</router-link>
                    <router-link class="hover:text-violet-1" to="/lavish-lashes/services">Services</router-link>
                    <router-link class="hover:text-violet-1" to="/lavish-lashes/products">Products</router-link>
                    <router-link class="hover:text-violet-1" to="/lavish-lashes/special-offers">Special Offer</router-link>
                    <router-link class="hover:text-violet-1" to="/lavish-lashes/FAQs">FAQs</router-link>
                    <a class="hover:text-violet-1" href="https://franchise.lay-bare.com/">Franchise</a>
                    <router-link class="hover:text-violet-1" to="/app">App</router-link>
                    <router-link class="hover:text-violet-1" to="/lavish-lashes/egift">E-Gift</router-link>
                </div>
                <button @click="toggleMenu" class="lg:hidden focus:outline-none" v-if="!isMenuOpen">
                    <i class="fas fa-bars text-3xl mt-1 text-black-3" />
                </button>
            </div>
        </div>
        <div v-if="isMenuOpen" class="fixed inset-0 lavish-menu-bg hamburger-menu-height">
            <div class="h-screen mx-auto px-1 flex flex-col">
                <button @click="toggleMenu" class="absolute fas fa-times right-8 top-4 mobileL:right-12 mobileL:top-8 text-4xl text-black-3"></button>
                <div class="mt-20">
                    <div class="flex mx-auto gap-2 mobileS:gap-4 items-center justify-center">
                        <a @click="changeBrand('laybare')" class="business-unit-dimension p-1 flex bg-green-14 rounded-full cursor-pointer">
                            <img class="w-full mt-1" src="../../assets/images/home-page/header/logo2.png" alt="Lay Bare">
                        </a>
                        <a @click="changeBrand('laybareplus')" class="business-unit-dimension p-1 flex bg-green-13 rounded-full cursor-pointer">
                            <img class="w-full mt-2 pt-0.5" src="../../assets/images/home-page/Lay-Bare-Plus.png" alt="Lay Bare Plus">
                        </a>
                        <a @click="changeBrand('passionails')" class="business-unit-dimension p-2.5 flex bg-violet-4 rounded-full cursor-pointer">
                            <img class="w-full m-auto" src="../../assets/images/home-page/passionails-logo-cropped.png" alt="Passionails">
                        </a>
                        <a @click="changeBrand('lavish-lashes')" class="business-unit-dimension p-2.5 flex bg-red-3 rounded-full cursor-pointer">
                            <img class="w-full m-auto" src="../../assets/images/home-page/lavishlashes-logo.png" alt="Lavish Lashes">
                        </a>
                    </div>
                </div>
                <div class="flex flex-col items-start text-white-1 gap-3 mobileS:gap-4 ml-10 mt-12 hamburger-menu-text-size">
                    <button class="font-semibold hover:text-violet-1" @click="navigate('/lavish-lashes')">Home</button>
                    <button class="font-semibold hover:text-violet-1" @click="navigate('/lavish-lashes/branches')">Location</button>
                    <button class="font-semibold hover:text-violet-1" @click="navigate('/lavish-lashes/services')">Services</button>
                    <button class="font-semibold hover:text-violet-1" @click="navigate('/lavish-lashes/products')">Products</button>
                    <button class="font-semibold hover:text-violet-1" @click="navigate('/lavish-lashes/special-offers')">Special Offer</button>
                    <button class="font-semibold hover:text-violet-1" @click="navigate('/lavish-lashes/FAQs')">FAQs</button>
                    <a class="font-semibold hover:text-violet-1" href="https://franchise.lay-bare.com/">Franchise</a>
                    <button class="font-semibold hover:text-violet-1" @click="navigate('/app')">App</button>
                    <button class="font-semibold hover:text-violet-1" @click="navigate('/lavish-lashes/egift')">E-Gift</button>
                </div>
                <div class="absolute icons-bottom-location justify-center w-full -ml-2">
                    <div class="flex relative justify-center mobileS:mt-4">
                        <img src="../../assets/images/home-page/lavishlashes-logo.png" alt="Lay Bare Brand" class="w-20 mobileS:w-24">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isMenuOpen: false,
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        changeBrand(brand) {
            let path = this.$route.fullPath.split('/');
            path[1] = brand;
            const finalPath = path.join('/');
            this.$router.push(finalPath);
        },
        navigate(path) {
            this.$router.push(path);
            this.toggleMenu();
        },
    },
};
</script>

<style scoped>
.header-container {
  height: 90px;
  font-size: 18px;
}

.lavish-menu-bg {
    background: rgb(229,134,157);
    background: linear-gradient(173deg, rgba(229,134,157,1) 0%, rgba(208,71,79,1) 100%);
    background-size: 100% 100%;
  }

.icons-bottom-location {
    bottom: 64px;
}

.hamburger-menu-text-size {
    font-size: 24px
}

.hamburger-menu-height {
    height: 100vh;
    overflow: hidden;
}

.business-unit-dimension {
  height: 70px;
  width: 70px;
}

@media (max-height: 670px) {
    .icons-bottom-location {
        bottom: 40px;
    }
    .hamburger-menu-text-size {
        font-size: 16px
    }
}

@media (max-width: 320px) {
  .business-unit-dimension {
    height: 56px;
    width: 56px;
  }
}

@media (max-width: 480px) {
  .header-container {
    height: 60px;
    font-size: 14px;
  }
}

@media (min-width: 1280px) {
    .header-container {
        height: 100px;
        font-size: 20px;
    }
}

@media (min-width: 1536px) {
    .header-container {
        height: 128px;
        font-size: 24px;
    }
}
</style>