<template>
    <div class="relative">
      <div class="w-full absolute block z-50">
        <Header/>
      </div>
      <div class="w-screen section-container space-top lavish-banner">
        <div class="relative h-full w-full flex xl:items-end items-center justify-center flex-col">
          <img src="../../assets/images/home-page/lavish-home/home-header.png" alt="Lavish Lashes Banner"
          class="absolute top-0 -left-48 mobileL:-left-64 xl:left-0 h-full z-5 object-cover overflow-x-visible">
          <div class="items-center flex-col z-10 text-center mobileL:ml-0 xl:mr-16 2xl:mr-36 ">
            <h1 class="font-historia ml-20 mobileL:ml-0 text-right mobileL:text-center text-4xl md:text-6xl 2xl:text-8xl
            text-white-1 leading-none mt-10 2xl:mt-16">
              The <span class="italic text-yellow-2">best lashes</span><br> in town!
            </h1>
          </div>
        </div>
      </div>
      <div class="relative w-full text-white-1 why-lavish py-16 mobileL:py-32 xl:py-44">
        <div class="flex flex-col inset-0 justify-center items-center text-center font-futura">
            <div class="text-center w-full">
              <h2 class="text-2xl mobileL:text-4xl 2xl:text-7xl font-semibold mobileL:font-normal">
                Why Lavish Lashes Studio?
              </h2>
              <img class="w-6 mobileL:w-10 xl:w-14 py-4 mobileL:py-10 mx-auto my-auto block" src="../../assets/images/home-page/lavish-home/shine.png" alt="shine">
            </div>
            <div class="max-w-xs mobileL:max-w-2xl text-sm gap-2 justify-center items-center mobileL:leading-none">
                <p class="font-semibold mobileL:font-normal text-sm mobileL:text-2xl xl:text-3xl 2xl:text-5xl">
                    Elevate your look with our
                    expertly crafted eyelash extensions. 
                    With lashes soft as silk for a light, 
                    soft, and comfortable feel fit for 
                    any occasion.
                </p>
            </div>
        </div>
      </div>
      <div class="bg-white-1 py-4 mobileL:py-8 lg:py-12 text-white-1">
          <div class="mx-auto w-5/6 2xl:gap-14 mobileL:gap-12 flex flex-col mobileL:flex-row justify-between flex-wrap gap-6 mobileL:text-sm lg:text-lg 2xl:text-2xl text-black-3
            font-futura font-semibold leading-tight">            
            <div class="flex-1 text-center flex flex-col items-center lash-sin-bg mobileL:py-10 xl:py-20">
                <img class="w-8 mobileL:w-16 xl:w-20 mb-4" src="../../assets/images/faqs-page/eyelash.png" alt="2">
                <p class="mt-2 mobileL:mt-4 xl:mt-4 max-w-xs">
                    My lashes appeared longer
                    lifted and beautifully curled, 
                    giving my eyes a more awake
                    and refreshed appearance 
                    I couldn't be happier with the 
                    outcome. It lasted for more that
                    a month as well!
                </p>
                <span class="bg-pink-17 px-4 mt-6 mobileL:mt-10 xl:mt-24 text-white-1 rounded-md">Jam C.</span>
                <hr class="w-48 h-1 mx-auto my-4 bg-black-3 border-0 rounded md:my-10 mobileL:hidden block">
            </div>
            <div class="flex-1 text-center flex flex-col items-center lash-sin-bg mobileL:py-10 xl:py-20" v-show="shouldShowElement || isVisible">
                <img class="w-8 mobileL:w-16 xl:w-20 mb-4" src="../../assets/images/faqs-page/eyelash.png" alt="2">
                <p class="mt-2 mobileL:mt-4 xl:mt-4 max-w-md">
                    I availed the Glamour Look and it was
                    really what I envisioned. It was simple
                    yet classy looking. The lash technicians
                    were also considerate to ask how I
                    was feeling during the whole process.
                    They're very light handed and I see
                    that they really know what they are
                    doing. I would definitely recommend it
                    to my friends and fam, thank you!
                </p>
                <span class="bg-pink-17 px-4 mt-6 mobileL:mt-10 text-white-1 rounded-md">Jana C.</span>
                <hr class="w-48 h-1 mx-auto my-4 bg-black-3 border-0 rounded md:my-10 mobileL:hidden block">
            </div>
            <div class="flex-1 text-center flex flex-col items-center lash-sin-bg mobileL:py-10 xl:py-20" v-show="shouldShowElement || isVisible">
                <img class="w-8 mobileL:w-16 xl:w-20 mb-4" src="../../assets/images/faqs-page/eyelash.png" alt="2">
                <p class="mt-2 mobileL:mt-4 xl:mt-4 max-w-sm">
                    I am super happy with my
                    experience and lash look! Lavish
                    artists are very skillful because I 
                    showed them my peg and they 
                    were able to do it, showing me 
                    different curls and lenghts. The 
                    lash artists hands were also super 
                    gaan and it really shows their
                    expertise. Thank you for the great
                    experience and service!
                </p>
                <span class="bg-pink-17 px-4 mt-6 mobileL:mt-10 text-white-1 rounded-md">Philene M.</span>
                <hr class="w-48 h-1 mx-auto my-4 bg-black-3 border-0 rounded md:my-10 mobileL:hidden block">
            </div>
            <button class="mobileL:hidden block bg-pink-17 text-white-1 font-semibold py-2 rounded-full text-base xl:text-xl 2xl:text-2xl mx-auto px-8"
              @click="showMoreFeedback()" ref="toggleButton">
              Load More
            </button>
          </div>
        <div class="flex flex-col mobileL:flex-row items-center justify-center gap-4 mobileL:gap-10 mt-10 mobileL:mt-20 mb-0 mobileL:mb-6">
          <img src="../../assets/images/home-page/lavish-home/homepage-1.png" alt="homepage 1" class="lavish-homepage-image">
          <img src="../../assets/images/home-page/lavish-home/homepage-2.png" alt="homepage 2" class="lavish-homepage-image">
          <img src="../../assets/images/home-page/lavish-home/homepage-3.png" alt="homepage 3" class="lavish-homepage-image">
        </div>
      </div>
      <div class="w-full absolute block z-20">
        <Footer/>
      </div>
    </div> 
</template>
  
<script>
import Header from '../../components/navigation/LavishHeader.vue'
import Footer from '../../components/footer/LavishFooter.vue'

export default {
  metaInfo: {
        title: "Lavish Lashes Studio",
        titleTemplate: "%s",
        htmlAttrs: {
          lang: "en-US",
        },
        meta: [
          { charset: "utf-8" },
          {
            vmid: 'description',
            name: "description",
            content:
              "Elevate your look with our expertly crafted eyelash extensions. With lashes soft as silk for a light, soft, and comfortable feel fit for any occasion.",
          },
          { vmid: 'thumbnail', name: "thumbnail", content: "https://lay-bare.com/lavishlashes-logo.png" },
          { property: "og:title", content: "Lavish Lashes Studio" },
          { property: "og:site_name", content: "Lavish Lashes Studio" },
          { property: "og:url", content: "https://lay-bare.com/lavish-lashes" },
          { property: "og:type", content: "website" },
          {
            vmid: 'og:description',
            property: "og:description",
            content:
              "Elevate your look with our expertly crafted eyelash extensions. With lashes soft as silk for a light, soft, and comfortable feel fit for any occasion.",
          },
          { property: "og:image", content: "https://lay-bare.com/lavishlashes-logo.png" },

          { vmid: 'twitter:card', name: "twitter:card", content: "summary" },
          { vmid: 'twitter:site', name: "twitter:site", content: "@Lay_Bare" },

          { name: "viewport", content: "width=device-width, initial-scale=1" },
          { name: "robots", content: "index,follow" },
        ]
    },

  components: {
    Header,
    Footer
  },
  data() {
    return {
        isVisible: false,
        windowWidth: window.innerWidth,
    }
  },
  computed: {
      shouldShowElement() {
      return this.windowWidth > 480;
      },
  },
  mounted() {
      window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
        this.windowWidth = window.innerWidth;
    },
    showMoreFeedback() {
        this.isVisible = !this.isVisible;
        this.$refs.toggleButton.innerText = this.isVisible ? 'Load Less!' : 'Load More!';
    }
  }
}
</script>
  
  <style scoped>
  .section-container {
    height: 700px;
  }
  
  .space-top {
    margin-top: 90px;
  }

  .lavish-homepage-image {
    height: auto;
    width: 100%;
    max-width: 28vw;
  }
  
  @media (max-width: 480px) {
    .space-top {
      margin-top: 60px;
    }
    .section-container {
      height: 45vh;
      overflow: hidden;
    }
    .lavish-homepage-image {
      height: auto;
      width: 92vw;
      max-width: none;
    }
  }

  @media (min-width: 480px) {
    .lash-sin-bg {
    background: radial-gradient(#ED7A97 -0%, #fef6ef 70%);
    border-radius: 10px;
    }
  }
  
  .lavish-banner {
    background: url('../../assets/images/faqs-page/lavish-header-banner.jpg') no-repeat;
    background-size: 100% 100%;
    font-family: 'Kannada';
  }

  .why-lavish {
    background: url('../../assets/images/faqs-page/full-set-lashes-banner.jpg') no-repeat;
    background-size: 100% 100%;
  }
  
  @media (min-width: 1280px) {
      .space-top {
        margin-top: 100px;
      }
      .section-container {
        height: calc(100vh - 100px);
      }
      .homepage-image {
        height: auto;
        width: 450px;
      }
      .lavish-homepage-image {
        height: auto;
        width: 100%;
        max-width: 30vw;
      }
  }
  
  @media (min-width: 1536px) {
      .space-top {
        margin-top: 128px;
      }
  
      .section-container {
        height: calc(100vh - 120px);
      }

      .homepage-image {
        height: auto;
        width: 500px;
      }
  }
  </style>